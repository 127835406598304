<div>
    <button type="button" class="my-btn my-btn-primary" (click)="computeStatsTrigger()">Compute Stats</button>
</div>
<div *ngIf="trigger">
    <label for="confirmationText">Type "compute stats" to confirm:&nbsp;</label>
    <input #inText name="confirmationText" placeholder="compute stats" />
    <button type="button" class="my-btn my-btn-primary" (click)="computeStats(inText.value)">Confirm</button>
    &nbsp;
    <button type="button" class="my-btn" (click)="computeStatsCancel()">Cancel</button>
</div>
<div *ngIf="success && trigger">Compute Stats executed successfully!</div>