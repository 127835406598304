import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private isSideNavOpened = false;
  public userDetails: any = {};
  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }
  logOut() {
  }
  toggleMenu() {
    const mainNavBar = document.querySelector('.main-navbar');
    const menuEle = document.querySelector('#menuDropdown');
    if (menuEle) {
      if (menuEle.classList.contains('show')) {
        menuEle.classList.remove('show');
        mainNavBar.classList.remove('main-nav--expanded');
      } else {
        menuEle.classList.add('show');
        mainNavBar.classList.add('main-nav--expanded');
      }
    }
  }
  toggleSideNav() {
    // this.apiService.sideNavOpened.next(!this.isSideNavOpened);
  }
}
