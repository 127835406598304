<div
  class="d-flex address-nav justify-content-end flex-wrap container-fluid"
>
  <ul class="address-nav__list">
    <li class="address-nav__item">
      <a class="address-nav__link" href="mailto:admin@sadisha.org"
        >admin@sadisha.org</a
      >
    </li>
    <li class="address-nav__item">
      <a class="address-nav__link text-nowrap" href="tel:+91-9550895968"
        >+91-9550895968</a
      >
    </li>
  </ul>
  <ul class="address-nav__list">
    <li class="address-nav__item">
      <a
        class="address-nav__link social-icon"
        href="https://www.facebook.com/sadisha.org/"
      >
        <span class="mdi mdi-facebook-box"></span>
      </a>
    </li>
    <li class="address-nav__item">
      <a
        class="address-nav__link social-icon"
        href="https://www.facebook.com/sadisha.org/"
      >
        <span class="mdi mdi-twitter-box"></span>
      </a>
    </li>
    <li class="address-nav__item">
      <a
        class="address-nav__link social-icon"
        href="https://www.facebook.com/sadisha.org/"
      >
        <span class="mdi mdi-instagram"></span>
      </a>
    </li>
  </ul>
</div>