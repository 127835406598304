<div class="contrainer-fluid feature">
    <h1 class="text-center f-22 m-t-32 m-b-20 font-italic primary-text">
        Click on the any card and explore more!...
    </h1>
    <div class="row align-items-center">
        <div class="col-12 col-md-4 text-center">
            <a class="feature__wrapper border p-5 d-block" [routerLink]="['../paper-upload']">
                <div class="feature__icon mb-3">
                    <img src="/assets/icons/mobile.svg" alt="" />
                </div>
                <h3 class="feature__header mb-3 ">
                    Evaluation Flow
                </h3>
                <div class="feature__description">
                    Click here to go to <b>Paper Upload </b>
                </div>
            </a>
        </div>
        <div class="col-12 col-md-4 text-center">
            <a class="feature__wrapper border p-5 d-block" [routerLink]="['../evaluation']">
                <div class="feature__icon mb-3">
                    <img src="/assets/icons/mobile.svg" alt="" />
                </div>
                <h3 class="feature__header mb-3 ">
                    Evaluation Flow
                </h3>
                <div class="feature__description">
                    Click here to go to <b>Evaluation Flow </b>
                </div>
            </a>
        </div>
        <div class="col-12 col-md-4 text-center">

            <a class="feature__wrapper border p-5 d-block" [routerLink]="['../student-edit']">
                <div class="feature__icon mb-3">
                    <img src="/assets/icons/admin.svg" alt="" />
                </div>
                <h3 class="feature__header mb-3">
                    Admin Features
                </h3>
                <div class="feature__description">
                    Click here to go to <b>Admin Features </b>
                </div>
            </a>
            <a class="feature__wrapper border p-5 d-block" [routerLink]="['../gallery-upload']">
                <div class="feature__icon mb-3">
                    <img src="/assets/icons/gallery.svg" alt="" />
                </div>
                <h3 class="feature__header mb-3">
                    Gallery Management
                </h3>
                <div class="feature__description">
                    Click here to go to <b>Gallery Management </b>
                </div>
            </a>
        </div>
        <div class="col-12 col-md-4 text-center">
            <a class="feature__wrapper border p-5 d-block" [routerLink]="['../status']">
                <div class="feature__icon mb-3">
                    <img src="/assets/icons/student-stots.svg" alt="" />
                </div>
                <h3 class="feature__header mb-3">
                    Students status
                </h3>
                <div class="feature__description">
                    Click here to go to <b>Students status </b>
                </div>
            </a>
        </div>
    </div>
</div>