export const CONSTANTS = {
  BASE_URL: "https://sadisha-be2.el.r.appspot.com",
  //BASE_URL: "http://localhost:8080",
  QUESTION_PAPERS: "5ec89bb82f0000d244db705a",
  FETCH_QUESTIONS: "contests/blah",
  QUESTIONS: "questionPaper",
  SUBMIT: "submission",
  LOGIN: "login",
  FORGOT_PSW: "forgotCreds",
  ANSWER_PAPER: "answerPaper",
  ANSWERS_BY_USER: "answers?userName=",
  ANSWER_PAPERS_BY_STATUS: "answerPapersStatus?pendingStatus=",
  ANSWER_PAPERS_BY_STATUS_BY_USER: "answerPapersStatusByUser?userName=",
  SCHOOLS: "schools",
  STUDENTS: "students",
  GET_STUDENT: "student",
  REGISTER_VOLUNTEER: "volunteerRegister?id=10",
  GALLERY: "gallery",
  GALLERY_EDIT: "galleryEdit",
  USERS: "users",
  STUDENTS_STATS: "studentsMap",
  UPLOAD_PHOTO: "uploadPhoto",
  UPLOAD_PAPER: "uploadPaperUsingSignedUrl",
  RESULTS_FLAG: "true",
  EXAM_CENTERS: "exam-centers",
  COMPUTE_STATS: "compute",
  RESULTS_SYNC: "resultsSync",
};
