<div class="p-12 footer">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-sm-4 align-self-center align-centre">
                <a href="http://sadisha.org/" class="navbar-brand logo">
                    <img src="assets/images/sadisha.png" class="img-fluid logo-img" alt="Logo">
                  </a>
            </div>
            <div class="col-sm-4 align-self-center align-centre">
                <h3 class="text-white">Contact Info</h3>
                <h4><a class="theme-color" href="mailto:admin@sadisha.org">admin@sadisha.org</a></h4>
                <h4><a class="theme-color" href="tel:+919550895968">+91-9550895968</a></h4>
            </div>
            <div class="col-sm-4 align-self-center align-centre">
                <div class='address-nav'>
                    <ul class="address-nav__list">
                        <li class="address-nav__item">
                            <a class="address-nav__link social-icon" href="https://www.facebook.com/sadisha.org/">
                                <span class="mdi mdi-facebook-box"></span>
                            </a>
                        </li>
                        <li class="address-nav__item">
                            <a class="address-nav__link social-icon" href="https://www.facebook.com/sadisha.org/">
                                <span class="mdi mdi-twitter-box"></span>
                            </a>
                        </li>
                        <li class="address-nav__item">
                            <a class="address-nav__link social-icon" href="https://www.facebook.com/sadisha.org/">
                                <span class="mdi mdi-instagram"></span>
                            </a>
                        </li>       
                    </ul>
                </div>
                <h3 class="text-white ml-3">Like Us on</h3>
            </div>
        </div>
    </div>
</div>