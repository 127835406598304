<app-admin-header></app-admin-header>
<div class="content-wrapper position-relative d-md-flex">
    <div class="app-sidebar">
        <app-side-nav></app-side-nav>
    </div>
    <div class="app-content w-100">
        <div class="p-16 router-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>