import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  allUsers: any = [];
  allRoles: Array<string> = [];
  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.allRoles = this.apiService.getAllRoles();
    this.getAllUsers();
  }
  getAllUsers() {
    try {
      this.apiService.showLoader.next(true);
      this.apiService.getAllUsers().subscribe(
        (res: any) => {
          this.apiService.showLoader.next(false);
          if (res && res.length) {
            this.allUsers = res;
            this.allUsers = this.allUsers.map(val => {
              val.assignedRoles = {};
              val.isEdit = false;
              this.allRoles.forEach(role => {
                if (val.userRoles && val.userRoles.indexOf(role) > -1) {
                  val.assignedRoles[role] = true;
                } else {
                  val.assignedRoles[role] = false;
                }
              });
              return val;
            });
            console.log('this.allUsers:', this.allUsers);
          }
        },
        (error) => {
          this.apiService.showLoader.next(false);
          // if (error.status === 401) {
          //   this.router.navigate(['/login']);
          //   return;
          // }
          if (error.error) {
            const serverError =
              typeof error.error === 'string' ? JSON.parse(error.error) : {};
            this.apiService.genericMessage(
              serverError.error || 'something went wrong',
              'error'
            );
          }
          console.log(error);
        }
      );
    } catch (e) {
      console.warn(e);
    }
  }
  editUserRoles(index) {
    this.allUsers = this.allUsers.map(val => {
      val.isEdit = false;
      return val;
    });
    this.allUsers[index].isEdit = true;
  }

  updateRoles(index, user) {
    try {
      const roles = [];
      Object.keys(user.assignedRoles).forEach(key => {
        if (user.assignedRoles[key]) {
          roles.push(key);
        }
      });
      this.apiService.showLoader.next(true);
      this.apiService.updateUserRoles(user.userName, {roles: roles.join(',')}).subscribe(
        (res: any) => {
          this.apiService.showLoader.next(false);
          console.log('res:', res);
          this.allUsers[index].isEdit = false;
          this.getAllUsers();
          if (res && res.length) {
          }
        },
        (error) => {
          this.apiService.showLoader.next(false);
          // if (error.status === 401) {
          //   this.router.navigate(['/login']);
          //   return;
          // }
          if (error.error) {
            const serverError =
              typeof error.error === 'string' ? JSON.parse(error.error) : {};
            this.apiService.genericMessage(
              serverError.error || 'something went wrong',
              'error'
            );
          }
          console.log(error);
        }
      );
    } catch (e) {
      console.warn(e);
    }
  }

}
