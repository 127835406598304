<div class="text-center bg-light p-3">
  <h5 class="">
    Student Details
    <span class="float-right hover text-right" (click)="closeTab()">
      <img
        width="40"
        style="border-radius: 50%; padding: 10px"
        src="../../../assets/icons/close-red-icon.svg"
      />
    </span>
  </h5>
</div>
<div *ngIf="showEditForm" class="container mb-2">
  <form [formGroup]="studentsForm" (submit)="updateStudent()">
    <div class="row">
      <div class="form-group col-12 col-md-6">
        <label class="label">
          Student Name <span class="required-star">*</span></label
        >
        <input
          type="text"
          formControlName="studentName"
          class="form-control"
          id="studentName"
        />
        <div
          class="f-14 error-message"
          *ngIf="
            (studentsForm.get('studentName').touched || formSubmitted) &&
            studentsForm.get('studentName').hasError('required')
          "
        >
          Please Enter Student Name
        </div>
      </div>
      <div class="form-group col-12 col-md-6">
        <label class="label">
          Parent Name <span class="required-star">*</span></label
        >
        <input
          type="text"
          formControlName="parentName"
          class="form-control"
          id="parentName"
        />
        <div
          class="f-14 error-message"
          *ngIf="
            (studentsForm.get('parentName').touched || formSubmitted) &&
            studentsForm.get('parentName').hasError('required')
          "
        >
          Please Enter Parent Name
        </div>
      </div>
      <div class="form-group col-12 col-md-6">
        <label class="label">
          Student Class <span class="required-star">*</span></label
        >
        <ng-select
          [items]="classesList"
          formControlName="studentClass"
          id="studentClass"
          name="studentClass"
          [clearable]="false"
        >
        </ng-select>
        <div
          class="f-14 error-message"
          *ngIf="
            (studentsForm.get('studentClass').touched || formSubmitted) &&
            studentsForm.get('studentClass').hasError('required')
          "
        >
          Please Select Class
        </div>
      </div>
      <div class="form-group col-12 col-md-6">
        <label class="label">
          Gender <span class="required-star">*</span></label
        >
        <ng-select
          [items]="genders"
          formControlName="gender"
          id="gender"
          name="gender"
          [clearable]="false"
        >
        </ng-select>
        <div
          class="f-14 error-message"
          *ngIf="
            (studentsForm.get('gender').touched || formSubmitted) &&
            studentsForm.get('gender').hasError('required')
          "
        >
          Please Select Gender
        </div>
      </div>
      <div class="form-group col-12 col-md-6">
        <label class="label">
          District <span class="required-star">*</span></label
        >
        <ng-select
          [items]="districtsList"
          formControlName="zone"
          id="zone"
          name="zone"
          bindLabel="name"
          [clearable]="false"
          (change)="onSelectDistrict()"
        >
        </ng-select>
        <div
          class="f-14 error-message"
          *ngIf="
            (studentsForm.get('zone').touched || formSubmitted) &&
            studentsForm.get('zone').hasError('required')
          "
        >
          Please Select District
        </div>
      </div>
      <div class="form-group col-12 col-md-6">
        <label class="label">
          Mandal <span class="required-star">*</span></label
        >
        <ng-select
          [items]="mandalsList"
          formControlName="mandal"
          id="mandal"
          name="mandal"
          bindLabel="name"
          [clearable]="false"
          (change)="onSelectMandal()"
        >
        </ng-select>
        <div
          class="f-14 error-message"
          *ngIf="
            (studentsForm.get('mandal').touched || formSubmitted) &&
            studentsForm.get('mandal').hasError('required')
          "
        >
          Please Select Mandal
        </div>
      </div>

      <div class="form-group col-12 col-md-6">
        <label class="label"
          >Select School <span class="required-star">*</span></label
        >
        <ng-select
          [items]="schoolsList"
          formControlName="schoolName"
          id="schoolName"
          name="schoolName"
          [clearable]="false"
        >
        </ng-select>
        <div
          class="f-14 error-message"
          *ngIf="
            (studentsForm.get('schoolName').touched || formSubmitted) &&
            studentsForm.get('schoolName').hasError('required')
          "
        >
          Please Select School
        </div>
      </div>

      <div class="form-group col-12 col-md-6">
        <label class="label">
          Contact Number <span class="required-star">*</span></label
        >
        <input
          type="text"
          numbersOnly
          maxlength="10"
          minlength="10"
          formControlName="contactNumber"
          class="form-control"
          id="contactNumber"
        />
        <div class="f-14 error-message">
          <span
            *ngIf="
              (studentsForm.get('contactNumber').touched || formSubmitted) &&
              studentsForm.get('contactNumber').hasError('required')
            "
          >
            Please Enter Contact Number
          </span>

          <span
            *ngIf="
              (studentsForm.get('contactNumber').touched || formSubmitted) &&
              studentsForm.get('contactNumber')?.value?.length > 0 &&
              studentsForm.get('contactNumber')?.value?.length < 10
            "
          >
            Please Enter Valid Contact Number
          </span>
        </div>
      </div>
    </div>
    <div class="m-t-28 text-center">
      <button
        type="submit"
        [disabled]="isAPILoading"
        class="my-btn my-btn-primary"
      >
        <span *ngIf="!isAPILoading">Update</span>
        <span *ngIf="isAPILoading">Updating...</span>
      </button>
      <button
        type="button"
        class="my-btn cancel-btn m-l-28"
        (click)="resetForm()"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
