<div class="page">
    <h1 class="page-header f-22 m-t-5 m-b-20 font-italic">Student Stats</h1>
    <div class="page-content">
        <div class="table-responsive">
            <table class="table text-center">
                <thead class="table-dark">
                    <tr>
                        <th>ZONES</th>
                        <th>NUMBER OF STUDENTS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let zone of zonesList; let i = index">
                        <td>{{zone.n}}</td>
                        <td>{{zone.s}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngFor="let zones of districtsList; let i = index" class="table-responsive">
            <h3 class="text-center">{{zones.name}}</h3>
            <table class="table text-center">
                <thead class="table-dark">
                    <tr>
                        <th>MANDALS</th>
                        <th>NUMBER OF STUDENTS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let mandal of zones.mandals; let i = index">
                        <td>{{mandal.name}}</td> 
                        <td>{{mandal.students}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>