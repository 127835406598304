<div>
<app-address-nav-header></app-address-nav-header>
  <nav class="navbar navbar-expand-lg navbar-light header container-fluid main-navbar">
    <button class="navbar-toggler" type="button" (click)="toggleMenu()" data-toggle="collapse" data-target="#menuDropdown"
      aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a href="http://sadisha.org/" class="navbar-brand logo">
      <img src="assets/images/sadisha.png" class="img-fluid logo-img" alt="Logo">
    </a>

    <div class="collapse navbar-collapse justify-content-end" id="menuDropdown">
      <div>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item px-12 mx-4" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/home']">
              Home
            </a>
          </li>
          <li class="nav-item px-12 mx-4" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/faq']">
              FAQ
            </a>
          </li>
          <li class="nav-item px-12 mx-4" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/talent-test']">
              MATHS TALENT TEST
            </a>
          </li>
          <li class="nav-item px-12 mx-4" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/gallery']">
              GALLERY
            </a>
          </li>
          <li class="nav-item px-12 mx-4" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/press']">
              PRESS
            </a>
          </li>
          <li class="nav-item px-12 mx-4" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/contact-us']">
              CONTACT US
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>