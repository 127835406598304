import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-compute-stats',
  templateUrl: './compute-stats.component.html',
  styleUrls: ['./compute-stats.component.scss']
})
export class ComputeStatsComponent implements OnInit {
  
  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
  }
  trigger: boolean = false;
  success: boolean = false;
  computeStatsTrigger() {
    this.trigger = true;
    console.log('computeStatsTrigger');
  }
  computeStatsCancel() {
    this.trigger = false;
    console.log('computeStatsCancel');
  }
  computeStats(inText : String) {
    console.log("Computing Stats....");
    if(inText == "compute stats") {
      this.apiService.computeStats().subscribe(
        (res) => {
          console.log(res);
          if(res === "Done") {
            console.log("Stats computed successfully");
            this.success = true;
          }
        }
      )
    }
  }

}
