<div class="page">
    <h1 class="page-header f-22 m-t-5 m-b-20 font-italic">User Management</h1>
    <div class="page-content">
        <div class="table-responsive">
            <table class="table text-center">
                <thead class="table-dark">
                    <tr>
                        <th>User Name</th>
                        <th *ngFor="let role of allRoles | slice:0:5">{{role}}</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of allUsers; let i = index">
                        <td>{{user.studentName || user.userName}}
                            [{{user.userName}} , {{user.password}}]
                        </td>
                        <td *ngFor="let role of allRoles  | slice:0:5;let j = index;">
                            <span *ngIf="!user.isEdit">
                                {{user.assignedRoles[role] ? 'Assigned' : 'Not Assigned'}}
                            </span>
                            <div *ngIf="user.isEdit" class="roles__toggle-wrapper">
                                <div class="onoffswitch onoffswitch--yes-or-no roles__toggle-switch">
                                    <input type="checkbox" 
                                    [(ngModel)]="user.assignedRoles[role]"                                
                                    [ngModelOptions]="{ standalone: true }"
                                    name="onoffswitch" 
                                    class="onoffswitch-checkbox" [id]="'active_toggle_'+i+'__'+j" [checked]="user.assignedRoles[role]">
                                    <label class="onoffswitch-label" [for]="'active_toggle_'+i+'__'+j">
                                      <span class="onoffswitch-inner"></span>
                                      <span class="onoffswitch-switch"></span>
                                    </label>
                                  </div>
                            </div>
                        </td>
                        <td>
                            <img *ngIf="!user.isEdit" class="edit-icon-img" src="assets/icons/edit-ico.svg" (click)="editUserRoles(i)" alt="edit"/>
                            <button *ngIf="user.isEdit" class="btn btn-small btn-primary" (click)="updateRoles(i, user)">Update</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>