<nav class="navbar navbar-expand-lg navbar-light header container-fluid">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarTogglerDemo03"
    aria-controls="navbarTogglerDemo03"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <a href="http://sadisha.org/" class="navbar-brand logo">
    <img
      src="assets/images/sadisha.png"
      class="img-fluid logo-img"
      alt="Logo"
    />
  </a>

  <div
    class="collapse navbar-collapse justify-content-end"
    id="navbarTogglerDemo03"
  >
    <div>
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <li class="nav-item px-12 mx-4" *ngIf="userDetails?.Name">
          <a class="nav-link text-capitalize" [routerLink]="['/login']">
            <span class="user-avator">{{ userId }}</span>
            {{ userDetails?.Name }} <br>
            
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
